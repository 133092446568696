<template>
  <div class="bj" :style="{backgroundImage:'url(' + info.background_img + ')'}">
    <div class="bj-mask">

    </div>
  </div>
</template>

<script>
export default {
  name: "home.vue",
  data(){
    return{
      info:{},
      btnList:[
        {
          name:'学生登录'
        },
        {
          name:'教师登录'
        },
        {
          name:'管理员登录'
        },
      ]
    }
  },
  created(){
    this.getInfo();
  },
  methods:{
    getInfo(){
      this.api.index.indexInit().then((res)=>{
        if(res.site){
          this.info = res.site;
        }else{
          this.$router.push({
            path:'https://saas.edu360.cn'
          })
        }

      })
    },
    goLogin(){
      this.$router.push({
        path:'/login'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bj{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size:cover;
}
.bj-mask{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.top{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #FFFFFF;
  >img{
    margin-right: 18px;
  }
}
.desc{
  margin: 50px 0 90px 0;
  width: 856px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
}
.btns{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btns-item{
  margin-right: 30px;
  width: 200px;
  height: 60px;
  background: rgba(255,255,255,0.9);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}
.btns-item:last-child{
  margin-right: 0;
}
.btns-item:hover{
  background: #3A6CFF;
  font-weight: bold;
  color: #FFFFFF;
}
</style>
